<template>
    <a-card style="background-color: #fff;">
        <a-tabs default-active-key="" @change="callback">
            <a-tab-pane key="" tab="全部">
            </a-tab-pane>
            <a-tab-pane key="0" tab="待发货订单">
            </a-tab-pane>
            <a-tab-pane key="1" tab="已发货订单">
            </a-tab-pane>
        </a-tabs>
        <div>
            <a-form layout="horizontal">
                <div :class="advanced ? null : 'fold'">
                    <a-row>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="下单时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-range-picker :value="dateValue" :ranges="ranges" @change="dateChange" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="search">查询</a-button>
                        </a-col>
                    </a-row>

                    <a-row>
                    </a-row>
                    <div style="margin-bottom: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
            queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
                </div>
            </a-form>
        </div>
        <div>
            <a-table :scroll="{ x: 1500 }" bordered :columns="columns" :dataSource="dataSource" :loading="tableLoading"
                size="middle" :pagination="false">
                <template slot="expressCode" slot-scope="record,text">
                    <span v-if="text.expressCode && text.expressCompany" style="color:green">已发货</span>
                    <span v-else style="color:red">未发货</span>
                </template>
                <template slot="expressCompany" slot-scope="record,text">
                    <span style="color:green" v-if="text.expressCode && text.expressCompany">{{ text.expressCompany }}:
                        {{ text.expressCode }}</span>
                </template>
                <template slot="createTime" slot-scope="record">{{ formatDateMin(record) }}</template>
                <div slot="action" slot-scope="record">
                    <a @click="showModal(0, record)"
                        v-if="!record.expressCode && record.sonStatus == 1 && record.payStatus == 2"
                        style="margin-left: 10px;">发货</a>
                    <a @click="showModal(1, record)" style="margin-left: 10px;"
                        v-if="!record.expressCode && record.sonStatus == 1 && record.payStatus == 2">取消订单</a>
                </div>
                <template slot="imagePath" slot-scope="record">
                    <img :src="record" style="width: 60px;max-height: 60px;" alt="">
                </template>
                <template slot="address" slot-scope="record">
                    <div>收货人: {{ record.name }}</div>
                    <div>收货人手机号: {{ record.mobile }}</div>
                    <div>收货地址: {{ record.province }}{{ record.city }}{{ record.area }}{{ record.addressDetails }}</div>
                </template>
            </a-table>
            <div class="page-box">
                <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                    :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                    show-size-changer />
            </div>
        </div>


        <a-modal v-model="openKC" title="变更库存" @ok="handleCk">
            <p>现有库存：{{ oldstock }}个</p>
            <p>
                变更为 <a-input v-model="stock" style="width:60px;" size="small" /> 个
            </p>
        </a-modal>

        <a-modal v-model="openActionLog" title="操作日志" :footer="null">
            <a-table bordered :columns="columnsActionLog" :dataSource="dataSourceActionLog"
                :loading="tableLogActionLoading" :pagination="false">
                <template slot="createTime" slot-scope="record">{{ formatDateMin(record) }}</template>
            </a-table>
            <div class="page-box">
                <a-pagination :total="logActionTotal" :current="logActionQuery.page + 1"
                    :page-size-options="pageSizeOptions" :pageSize="logActionQuery.size" @change="handlePageChange"
                    @showSizeChange="onShowSizeChange" show-size-changer />
            </div>
        </a-modal>

        <a-modal v-model="fhOpen" title="填写快递信息" @ok="handlefh">
            <a-form-model >
                <a-form-model-item label="物流公司" required style="margin:0 20px;">
                    <a-select placeholder="请选择物流公司" v-model="fhObj.type">
                        <a-select-option :value="item.code" v-for="item in expList" :key="item.id">{{ item.name }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="物流单号" required style="margin:0 20px;">
                    <a-input v-model="fhObj.code" placeholder="请输入物流单号" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <a-modal v-model="downOpen" title="取消订单" @ok="handleDown">
            <a-textarea v-model="reason" placeholder="请输入取消原因"></a-textarea>
        </a-modal>


    </a-card>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
const columns = [
    { title: '序号', width: 80, customRender: (text, record, index) => index + 1, align: 'center' },
    { title: '下单时间', width: 160, dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
    { title: '订单号', dataIndex: 'code', align: 'center', width: 220 },
    { title: '收货信息', dataIndex: 'address', align: 'center', width: 300, scopedSlots: { customRender: 'address' } },
    // { title: '供应商订单号', dataIndex: 'code', align: 'center' },
    { title: '商品名称', dataIndex: 'goodsName', align: 'center',width: 140, },
    { title: '商品图片', width: 140, dataIndex: 'imagePath', align: 'center', scopedSlots: { customRender: 'imagePath' } },
    { title: '规格', dataIndex: 'goodsSpecs', align: 'center' ,width: 140,},
    { title: '商品数量', width: 100, dataIndex: 'totalQuantity', align: 'center' },
    { title: '结算单价', width: 100, dataIndex: 'price', align: 'center' },
    { title: '订单结算总价', width: 140, dataIndex: 'totalPrice', align: 'center' },
    { title: '订单状态', width: 120, dataIndex: 'sonStatusStr', align: 'center' },
    
    { title: '发货状态', width: 100, dataIndex: 'expressCode', align: 'center', scopedSlots: { customRender: 'expressCode' } },
    { title: '物流单号', dataIndex: 'expressCompany', align: 'center',width: 240, scopedSlots: { customRender: 'expressCompany' } },
    // { title: '退款状态', dataIndex: 'auditStatusDesc', align: 'center' ,scopedSlots: { customRender: 'auditStatusDesc' } },
    { title: '操作', width: 160, align: 'center', fixed: "right", scopedSlots: { customRender: 'action' } },
]
export default {
    name: 'QueryList',
    data() {
        return {
            moment,

            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,

            currentPage: 1,

            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '当月': [moment().startOf('month'), moment().endOf('month')],
                '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            isModalVisible: false,
            confirmLoading: false,
            formData: {},
            categoryList: [{ id: 0, name: '草稿' }, { id: 1, name: '审核中' }, { id: 2, name: '审核通过' }, { id: 3, name: '审核未通过' }],
            userInfo: {},
            // 日志
            logQuery: {
                page: 0,
                size: 10
            },
            columnsLog: [],
            dataSourceLog: [
                { title: '审核状态', dataIndex: 'typeDesc', align: 'center', },
                { title: '操作时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
                { title: '操作人', dataIndex: 'skuCount', align: 'center', },
                { title: '反馈详情', dataIndex: 'skuCount', align: 'center', },
            ],
            tableLogLoading: false,
            openLog: false,
            logTotal: 0,

            // 操作日志
            openActionLog: false,
            dataSourceActionLog: [],
            columnsActionLog: [],
            tableLogActionLoading: false,
            logActionQuery: {
                page: 0,
                size: 10
            },
            logActionTotal: 0,



            // 库存
            openKC: false,
            oldstock: 0,
            stock: "",
            editId: "",


            // 下架
            downOpen: false,
            reason: "",

            // 品类
            categoryOptions: [{ value: '1', label: '商城', isLeaf: false }],
            expList: [],
            fhOpen: false,
            actionObj:{},
            fhObj:{}
        }
    },
    mounted() {
        this.getData()
        this.getExpList();//获取快递公司
    },
    methods: {
        getExpList() {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/express/company/list', 'get',).then(res => {
                this.expList = res.data.data
            })
        },
        categoryChange(value) {
            this.queryData.classifyList = value;
        },
        getCategoryList(value) {
            let id = value[value.length - 1].value
            let url = process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + id + '/0'
            if (id != 1) {
                url = process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + 1 + '/' + id
            }
            request(url, 'get').then((res) => {
                let data = res.data.data;
                console.log(data, 'data')
                data.forEach(item => {
                    item.value = item.id
                    item.label = item.name
                    if (item.level < 3) {
                        item.isLeaf = false
                    }
                })
                if (value.length != 3) {
                    if (id == 1) {
                        this.categoryOptions.forEach(item => {
                            if (item.value == id) {
                                item.children = data;
                            }
                        })
                    } else {
                        this.categoryOptions[0].children.forEach(item => {
                            if (item.value == id) {
                                item.children = data;
                            }
                        })
                    }
                } else {
                    this.categoryOptions[0].children.forEach(item => {
                        if (item.id == value[1].id) {
                            item.children.forEach(it => {
                                if (it.id == value[2].id) {
                                    it.children = data;
                                }
                            })
                        }
                    })
                }

            })
        },
        handleCk() {
            if (!this.stock) {
                this.$message.error('请输入库存数量！')
                return false
            }
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goodsSpec/editStock', 'post', { id: this.editId, stock: this.stock - 0 }).then(res => {
                if (res.data.code == 200) {
                    this.openKC = false;
                    this.$message.success('修改成功！')
                    this.getData()
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        callback(e) {
            this.queryData.fhStatus = e;
            this.getData();
        },
        dateChange(date, dateString) {
            this.dateValue = date;
            if(dateString) {
                this.queryData.dateMin = dateString[0] + " 00:00:00"
                this.queryData.dateMax = dateString[1] + " 23:59:59"
            } else {
                this.queryData.dateMin = ""
                this.queryData.dateMax = ""
            }
            
        },
        fileLink(url) {
            window.open(url, '_blank')
        },
        showModal(type, record) {
            if (type == 0) {
                this.getExpList()
                this.fhObj={}
                this.fhOpen = true;
            } else {
                this.downOpen = true;
            }
            this.actionObj = record;
        },
        handlefh() {
            if(!this.fhObj.type){
                this.$message.error('请选择物流公司！')
                return false;
            }
            if(!this.fhObj.code){
                // code
                this.$message.error('请输入物流单号！')
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'srm/orders/express/'+this.actionObj.ordersDetailsId, 'post', this.fhObj).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('操作成功！')
                    this.fhOpen = false;
                    this.getData()
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        editStatus(data) {
            if (data.status == 2) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/goodsSpec/editStatus', 'post', {
                    id: data.id, reason: "", status: 1
                }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('上架成功！')
                        this.getData()
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            } else {
                this.editId = data.id
                this.downOpen = true
            }

        },
        handleDown() {
            if (!this.reason) {
                this.$message.error('请输入取消原因！')
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'srm/orders/close/'+this.actionObj.id+'/'+this.actionObj.ordersDetailsId, 'post', { reason: this.reason  }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('订单取消成功！')
                    this.reason = ""
                    this.downOpen = false;
                    this.getData()
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        getUserInfo(id) {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/detail/' + id, 'post', {}).then(res => {
                this.userInfo = res.data.data;
                this.formData = this.userInfo.qualificationFileList[0]
                this.isModalVisible = true;
            })
        },
        handleCancel() {
            this.isModalVisible = false; // 取消操作
        },
        submit(data) {
            // 100 公司资质 200 品牌资质 300厂商资质
            data.type = 100
            if (this.userInfo.id) {
                data.id = this.userInfo.id
                data.companyId = this.userInfo.companyId
            }
            if (data.id) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/update', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('更新成功！')
                        this.isModalVisible = false;
                        this.formData = {}
                    }
                })
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/save', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('保存成功！')
                        this.isModalVisible = false;
                        this.formData = {}
                    }
                })
            }
        },
        updateStatus(id, status) {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/updateStatus', 'post', {
                id, status
            }).then(res => {
                if (res.data.code == 200) {
                    this.getData();
                    this.$message.success('操作成功！')
                    this.isModalVisible = false;
                }
            })

        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 10,
                auditStatus: this.queryData.auditStatus
            }
            this.dateValue = ''
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/orders/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/cake/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            // const hours = date.getHours().toString().padStart(2, '0');
            // const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            // let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            let result = year + '-' + month + '-' + day
            return result
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            return result
        },
    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>